<template>
	<div v-if="!$globalLoading">
		<!-- Back Button -->
		<div>
			<button @click="onClickBack">
				<v-avatar size="40" class="tw-bg-ee">
					<v-icon color="black">mdi-chevron-left</v-icon>
				</v-avatar>
				<span
					class="tw-font-pop tw-text-base tw-font-normal tw-text-black tw-ml-4"
					>{{ $t('actions.back') }}</span
				>
			</button>
		</div>
		<h3
			class="tw-font-semibold tw-text-black tw-font-pop tw-text-19px tw-mt-4 lg:tw-mt-7 tw-mb-6 lg:tw-mb-12"
		>
			{{ $t('establishment.companyInfo') }}
		</h3>
		<v-form v-if="!loading" readonly>
			<v-row>
				<v-col cols="12">
					<div>
						<div class="tw-flex tw-items-center tw-space-s-3.5 tw-mb-4">
							<v-icon color="#D5D5D5"
								>mdi-office-building-outline</v-icon
							>
							<span class="tw-font-medium tw-text-black">
								{{ $t('establishment.companyName') }}
							</span>
						</div>
						<v-text-field
							outlined
							:height="$vuetify.breakpoint.lgAndUp ? '60px' : ''"
							:rules="[rules.required($t('name'))]"
							v-model="establishment.company.name"
							:placeholder="$t('signUp.companyNamePlaceholder')"
							hide-details="auto"
							class="custom-outlined placeholder-padding tw-rounded-md"
						/>
					</div>
				</v-col>

				<v-col cols="12" md="6">
					<div>
						<div class="tw-flex tw-items-center tw-space-s-3.5 tw-mb-4">
							<v-icon color="#D5D5D5">mdi-file-document-outline</v-icon>
							<span class="tw-font-medium tw-text-black">{{
								$t('signUp.commercialRegistrationNo')
							}}</span>
						</div>
						<v-text-field
							outlined
							color="#F0F0F0"
							:height="$vuetify.breakpoint.lgAndUp ? '60px' : ''"
							:rules="[rules.required($t('number'))]"
							v-model="establishment.company.registrationNumber"
							class="custom-outlined placeholder-padding tw-rounded-md"
							:placeholder="
								$t('signUp.commercialRegistrationNoPlaceholder')
							"
							hide-details="auto"
						/>
					</div>
				</v-col>
				<v-col cols="12" md="6">
					<div>
						<div class="tw-flex tw-items-center tw-space-s-3.5 tw-mb-4">
							<v-icon color="#D5D5D5">mdi-calendar-outline</v-icon>
							<span class="tw-font-medium tw-text-black">
								{{ $t('signUp.recordExpirationDate') }}
							</span>
						</div>

						<v-menu
							ref="menu"
							v-model="menu"
							:close-on-content-click="false"
							:return-value.sync="establishment.company.recordExpDate"
							transition="scale-transition"
							offset-y
							min-width="auto"
						>
							<template v-slot:activator="{ on, attrs }">
								<v-text-field
									outlined
									color="#F0F0F0"
									:rules="[rules.required($t('date'))]"
									v-model="establishment.company.recordExpDate"
									class="custom-outlined placeholder-padding tw-rounded-md"
									type="date"
									:height="$vuetify.breakpoint.lgAndUp ? '60px' : ''"
									:placeholder="
										$t('signUp.recordExpirationDatePlaceholder')
									"
									hide-details="auto"
									v-bind="attrs"
									v-on="on"
								/>
							</template>
							<v-date-picker
								v-model="establishment.company.recordExpDate"
								no-title
								scrollable
							>
								<v-spacer></v-spacer>
								<v-btn text @click="menu = false">
									{{ $t('cancel-label') }}
								</v-btn>
								<v-btn
									text
									@click="
										$refs.menu.save(
											establishment.company.recordExpDate
										)
									"
									>{{ $t('ok') }}</v-btn
								>
							</v-date-picker>
						</v-menu>
					</div>
				</v-col>

				<v-col cols="12">
					<div>
						<div class="tw-flex tw-items-center tw-space-s-3.5 tw-mb-4">
							<v-icon color="#D5D5D5">mdi-store-outline</v-icon>
							<span class="tw-font-medium tw-text-black">{{
								$t('signUp.storeName')
							}}</span>
						</div>
						<v-text-field
							outlined
							:height="$vuetify.breakpoint.lgAndUp ? '60px' : ''"
							color="#F0F0F0"
							v-model="establishment.company.storeName"
							class="custom-outlined placeholder-padding tw-rounded-md"
							:placeholder="$t('signUp.storeNamePlaceholder')"
							hide-details="auto"
						/>
					</div>
				</v-col>
				<v-col cols="12" md="6">
					<div>
						<div class="tw-flex tw-items-center tw-space-s-3.5 tw-mb-4">
							<v-icon color="#D5D5D5">mdi-phone</v-icon>
							<span class="tw-font-medium tw-text-black">{{
								$t('signUp.phoneNumber')
							}}</span>
						</div>
						<v-text-field
							outlined
							color="#F0F0F0"
							type="tel"
							:height="$vuetify.breakpoint.lgAndUp ? '60px' : ''"
							:rules="[rules.required($t('phoneNumber'))]"
							v-model="establishment.company.phone"
							class="custom-outlined placeholder-padding tw-rounded-md"
							:placeholder="$t('signUp.phoneNumberPlaceholder')"
							hide-details="auto"
						/>
					</div>
				</v-col>
				<v-col cols="12" md="6">
					<div>
						<div class="tw-flex tw-items-center tw-space-s-3.5 tw-mb-4">
							<v-icon color="#D5D5D5">mdi-email</v-icon>
							<span class="tw-font-medium tw-text-black">
								{{ $t('signUp.companyEmail') }}
							</span>
						</div>
						<v-text-field
							outlined
							:height="$vuetify.breakpoint.lgAndUp ? '60px' : ''"
							color="#F0F0F0"
							:rules="[rules.required($t('email')), rules.email]"
							type="email"
							v-model="establishment.company.email"
							class="custom-outlined placeholder-padding tw-rounded-md"
							:placeholder="$t('signUp.companyEmailPlaceholder')"
							hide-details="auto"
						/>
					</div>
				</v-col>
				<v-col cols="12" md="6">
					<div>
						<div class="tw-flex tw-items-center tw-space-s-3.5 tw-mb-4">
							<v-icon color="#D5D5D5">mdi-briefcase-outline</v-icon>
							<span class="tw-font-medium tw-text-black">
								{{ $t('signUp.mainActivity') }}
							</span>
						</div>
						<v-text-field
							outlined
							:height="$vuetify.breakpoint.lgAndUp ? '60px' : ''"
							color="#F0F0F0"
							:rules="[rules.required($t('signUp.mainActivity'))]"
							class="custom-outlined placeholder-padding tw-rounded-md"
							:placeholder="$t('signUp.mainActivityPlaceholder')"
							hide-details="auto"
							v-model="establishment.company.mainActivity"
						/>
						<!-- <v-select
							hide-details="auto"
							:rules="[rules.required('Main Activity')]"
							single-line
							class="custom-outlined placeholder-padding tw-rounded-md"
							:height="$vuetify.breakpoint.lgAndUp ? '60px' : ''"
							:placeholder="$t('signUp.mainActivityPlaceholder')"
							:items="activity"
							item-text="name"
							item-value="id"
							v-model="establishment.company.mainActivity"
							label="Main Activity"
							outlined
							color="#F0F0F0"
						></v-select> -->
					</div>
				</v-col>
				<v-col cols="12" md="6">
					<div>
						<div class="tw-flex tw-items-center tw-space-s-3.5 tw-mb-4">
							<v-icon color="#D5D5D5">mdi-briefcase-outline</v-icon>
							<span class="tw-font-medium tw-text-black">{{
								$t('signUp.subActivity')
							}}</span>
						</div>

						<!-- <v-select
							hide-details="auto"
							:rules="[rules.required('Sub Activity')]"
							class="custom-outlined placeholder-padding tw-rounded-md"
							:placeholder="$t('signUp.subActivityPlaceholder')"
							:items="subActivity"
							item-text="name"
							single-line
							item-value="id"
							:height="$vuetify.breakpoint.lgAndUp ? '60px' : ''"
							v-model="establishment.company.subActivity"
							label="Sub Activity"
							outlined
							color="#F0F0F0"
						></v-select> -->
						<v-text-field
							outlined
							:height="$vuetify.breakpoint.lgAndUp ? '60px' : ''"
							color="#F0F0F0"
							class="custom-outlined placeholder-padding tw-rounded-md"
							:placeholder="$t('signUp.subActivityPlaceholder')"
							hide-details="auto"
							v-model="establishment.company.subActivity"
						/>
					</div>
				</v-col>
			</v-row>

			<h3
				class="tw-font-semibold tw-font-pop tw-text-19px tw-mt-4 lg:tw-mt-7 tw-mb-6 lg:tw-mb-12"
			>
				{{ $t('establishment.bankAccInfo') }}
			</h3>
			<v-row>
				<v-col cols="12">
					<div>
						<div class="tw-flex tw-items-center tw-space-s-3.5 tw-mb-4">
							<v-icon color="#D5D5D5">mdi-account-outline</v-icon>
							<span class="tw-font-medium tw-text-black">{{
								$t('signUp.accountName')
							}}</span>
						</div>
						<v-text-field
							outlined
							:rules="[rules.required($t('signUp.accountName'))]"
							v-model="establishment.bank.accountName"
							class="custom-outlined placeholder-padding tw-rounded-md"
							:placeholder="$t('signUp.accountNamePlaceholder')"
							hide-details="auto"
						/>
					</div>
				</v-col>

				<v-col cols="12" md="6">
					<div>
						<div class="tw-flex tw-items-center tw-space-s-3.5 tw-mb-4">
							<v-icon color="#D5D5D5">mdi-card-text-outline</v-icon>
							<span class="tw-font-medium tw-text-black">
								{{ $t('signUp.accountNumber') }}
							</span>
						</div>
						<v-text-field
							outlined
							:rules="[rules.required($t('signUp.accountNumber'))]"
							v-model="establishment.bank.accountNumber"
							class="custom-outlined placeholder-padding tw-rounded-md"
							:placeholder="$t('signUp.accountNumberPlaceholder')"
							hide-details="auto"
						/>
					</div>
				</v-col>
				<v-col cols="12" md="6">
					<div>
						<div class="tw-flex tw-items-center tw-space-s-3.5 tw-mb-4">
							<v-icon color="#D5D5D5">mdi-bank-outline</v-icon>
							<span class="tw-font-medium tw-text-black">{{
								$t('signUp.bankName')
							}}</span>
						</div>

						<v-text-field
							outlined
							:rules="[rules.required($t('signUp.bankName'))]"
							v-model="establishment.bank.bankName"
							class="custom-outlined placeholder-padding tw-rounded-md"
							:placeholder="$t('signUp.bankNamePlaceholder')"
							hide-details="auto"
						/>
					</div>
				</v-col>

				<v-col cols="12">
					<div>
						<div class="tw-flex tw-items-center tw-space-s-3.5 tw-mb-4">
							<v-icon dark>mdi-home</v-icon>
							<span class="tw-font-medium tw-text-black">
								{{ $t('signUp.companyRepresentativeName') }}
							</span>
						</div>
						<v-text-field
							outlined
							:rules="[rules.required($t('name'))]"
							v-model="establishment.supplier.representativeName"
							class="custom-outlined placeholder-padding tw-rounded-md"
							:placeholder="$t('signUp.companyRepresentativeName')"
							hide-details="auto"
						/>
					</div>
				</v-col>
			</v-row>
			<!-- national address -->
			<h3
				class="tw-font-semibold tw-font-pop tw-text-19px tw-mt-4 lg:tw-mt-7 tw-mb-6 lg:tw-mb-12"
			>
				{{ $t('establishment.adress') }}
			</h3>
			<v-row>
				<v-col cols="12" md="6">
					<div>
						<div class="tw-flex tw-items-center tw-space-s-3.5 tw-mb-4">
							<v-icon color="#D5D5D5">mdi-map-marker-outline</v-icon>
							<span class="tw-font-medium tw-text-black">
								{{ $t('signUp.buildingAddress') }}
							</span>
						</div>
						<v-text-field
							outlined
							:rules="[rules.required($t('signUp.buildingAddress'))]"
							v-model="establishment.address.buildingNumber"
							class="custom-outlined placeholder-padding tw-rounded-md"
							tw-rounded-md
							:placeholder="$t('signUp.buildingAddressPlaceholder')"
							hide-details="auto"
						/>
					</div>
				</v-col>

				<v-col cols="12" md="6">
					<div>
						<div class="tw-flex tw-items-center tw-space-s-3.5 tw-mb-4">
							<v-icon color="#D5D5D5">mdi-map-marker-outline</v-icon>
							<span class="tw-font-medium tw-text-black">{{
								$t('signUp.streetName')
							}}</span>
						</div>
						<v-text-field
							outlined
							:rules="[rules.required($t('signUp.streetName'))]"
							v-model="establishment.address.streetName"
							class="custom-outlined placeholder-padding tw-rounded-md"
							tw-rounded-md
							:placeholder="$t('signUp.streetNamePlaceholder')"
							hide-details="auto"
						/>
					</div>
				</v-col>
				<v-col cols="12" md="6">
					<div>
						<div class="tw-flex tw-items-center tw-space-s-3.5 tw-mb-4">
							<v-icon color="#D5D5D5">mdi-map-marker-outline</v-icon>
							<span class="tw-font-medium tw-text-black">{{
								$t('signUp.neighbourhoodName')
							}}</span>
						</div>
						<v-text-field
							outlined
							:rules="[rules.required($t('signUp.neighbourhoodName'))]"
							v-model="establishment.address.neighborhoodName"
							class="custom-outlined placeholder-padding tw-rounded-md"
							tw-rounded-md
							:placeholder="$t('signUp.neighbourhoodNamePlaceholder')"
							hide-details="auto"
						/>
					</div>
				</v-col>

				<v-col cols="12" md="6">
					<div>
						<div class="tw-flex tw-items-center tw-space-s-3.5 tw-mb-4">
							<v-icon color="#D5D5D5">mdi-map-marker-outline</v-icon>
							<span class="tw-font-medium tw-text-black">{{
								$t('signUp.city')
							}}</span>
						</div>
						<v-text-field
							outlined
							v-model="establishment.address.city"
							class="custom-outlined placeholder-padding tw-rounded-md"
							:placeholder="$t('signUp.cityPlaceholder')"
							hide-details="auto"
						/>
					</div>
				</v-col>
				<v-col cols="12" md="6">
					<div>
						<div class="tw-flex tw-items-center tw-space-s-3.5 tw-mb-4">
							<v-icon color="#D5D5D5">mdi-map</v-icon>
							<span class="tw-font-medium tw-text-black">{{
								$t('signUp.postalCode')
							}}</span>
						</div>
						<v-text-field
							outlined
							:rules="[rules.required($t('signUp.postalCode'))]"
							type="number"
							v-model="establishment.address.postalCode"
							class="custom-outlined placeholder-padding tw-rounded-md"
							:placeholder="$t('signUp.postalCodePlaceholder')"
							hide-details="auto"
						/>
					</div>
				</v-col>
				<v-col cols="12" md="6">
					<div>
						<div class="tw-flex tw-items-center tw-space-s-3.5 tw-mb-4">
							<v-icon color="#D5D5D5"
								>mdi-office-building-outline</v-icon
							>
							<span class="tw-font-medium tw-text-black">{{
								$t('signUp.unitNumber')
							}}</span>
						</div>
						<v-text-field
							outlined
							:rules="[rules.required($t('signUp.unitNumber'))]"
							type="number"
							v-model="establishment.address.unitNumber"
							class="custom-outlined placeholder-padding tw-rounded-md"
							:placeholder="$t('signUp.unitNumberPlaceholder')"
							hide-details="auto"
						/>
					</div>
				</v-col>
				<v-col cols="12" md="6">
					<div>
						<div class="tw-flex tw-items-center tw-space-s-3.5 tw-mb-4">
							<v-icon color="#D5D5D5"
								>mdi-office-building-outline</v-icon
							>
							<span class="tw-font-medium tw-text-black">{{
								$t('signUp.extraNumber')
							}}</span>
						</div>

						<v-text-field
							outlined
							:rules="[rules.required($t('signUp.extraNumber'))]"
							type="number"
							class="custom-outlined placeholder-padding tw-rounded-md"
							:placeholder="$t('signUp.extraNumberPlaceholder')"
							v-model="establishment.address.extraNumber"
							hide-details="auto"
						/>
					</div>
				</v-col>
			</v-row>
			<h3
				class="tw-font-semibold tw-font-pop tw-text-19px tw-mt-4 lg:tw-mt-7 tw-mb-6 lg:tw-mb-12"
			>
				{{ $t('establishment.attachments') }}
			</h3>
			<BaseUpload v-model="attachments" readonly actions-in-line />
			<!-- <v-simple-table v-if="attachments.length" class="tw-mt-4">
				<template v-slot:default>
					<tbody>
						<template v-for="(file, i) in attachments">
							<tr
								class="tw-border-t tw-border-b tw-border-opacity-10 tw-border-70 tw-py-4"
								:key="i"
								v-if="file.attachment"
							>
								<td>{{ i + 1 }}</td>

								<td class="tw-space-s-2">
									<v-icon color="primary">mdi-file</v-icon>
									<span>{{
										file.attachment && file.attachment.attachmentName
									}}</span>
								</td>
								<td>
									<span v-html="file.name"></span>
								</td>
								<td>
									<span
										>{{
											file.attachment && file.attachment.attachedSize
										}}
										KB</span
									>
								</td>
							</tr>
						</template>
					</tbody>
				</template>
			</v-simple-table> -->
		</v-form>
		<h3
			class="tw-font-semibold tw-font-pop tw-text-19px tw-mt-4 lg:tw-mt-7 tw-mb-6 lg:tw-mb-12"
			v-if="
				establishment.supplier && establishment.supplier.status != 'active'
			"
		>
			{{ $t('adminApproval.accreditationDecision') }}
		</h3>
		<v-form
			v-if="
				establishment.supplier && establishment.supplier.status != 'active'
			"
			ref="approval"
			@submit.prevent="onSubmitApproved"
		>
			<v-row>
				<v-col cols="6">
					<div>
						<div class="tw-flex tw-items-center tw-space-s-3.5 tw-mb-4">
							<v-icon color="#D5D5D5">mdi-check</v-icon>
							<span class="tw-font-medium tw-text-black">
								{{ $t('adminApproval.apprvedStatus') }}
							</span>
						</div>
						<v-select
							v-model="approval.status"
							hide-details="auto"
							:items="statuses"
							:label="$t('adminApproval.apprvedStatus')"
							:placeholder="$t('adminApproval.apprvedStatus')"
							:rules="[rules.required($t('statusText'))]"
							:height="$vuetify.breakpoint.lgAndUp ? '60px' : ''"
							outlined
							single-line
							color="#F0F0F0"
							class="custom-outlined placeholder-padding tw-rounded-md"
						></v-select>
					</div>
				</v-col>
				<v-col v-if="approval.status != 'active'" cols="6">
					<div>
						<div class="tw-flex tw-items-center tw-space-s-3.5 tw-mb-4">
							<v-icon color="#D5D5D5">mdi-check</v-icon>
							<span class="tw-font-medium tw-text-black">
								{{ $t('adminApproval.rejectedReason') }}
							</span>
						</div>
						<v-select
							hide-details="auto"
							:rules="[
								rules.required($t('adminApproval.rejectedReason')),
							]"
							single-line
							class="custom-outlined placeholder-padding tw-rounded-md"
							:height="$vuetify.breakpoint.lgAndUp ? '60px' : ''"
							:placeholder="$t('adminApproval.rejectedReason')"
							:items="$rejectCategories.data"
							item-text="name"
							item-value="id"
							v-model="approval.reason"
							outlined
							color="#F0F0F0"
						></v-select>
					</div>
				</v-col>
				<v-col cols="12">
					<div
						class="tw-mb-4 tw-font-medium tw-flex tw-items-center tw-text-47"
					>
						{{ $t('adminApproval.sendNotes') }}
					</div>

					<v-textarea
						outlined
						rows="4"
						v-model="approval.notes"
						flat
						auto-grow
						class="custom-outlined tw-rounded-md"
						:placeholder="$t('adminApproval.sendNotes')"
						:rules="[rules.required($t('notes'))]"
						dense
					></v-textarea>
				</v-col>
			</v-row>
			<div class="tw-flex tw-w-full tw-space-s-9 tw-mt-2 tw-mb-4">
				<v-btn
					outlined
					color="primary"
					height="58"
					class="tw-flex-1 tw-rounded-lg"
					text
					@click="$router.back()"
					><span
						class="tw-text-lg tw-font-medium tw-capitalize tw-text-72"
						>{{ $t('actions.cancel') }}</span
					>
				</v-btn>
				<v-btn
					type="submit"
					height="58"
					class="tw-flex-1 tw-font-medium tw-rounded-lg"
					depressed
					:loading="loading"
					color="primary"
					><span
						class="tw-text-lg tw-font-medium tw-capitalize tw-text-white"
						>{{ $t('actions.confirm') }}</span
					>
				</v-btn>
			</div>
		</v-form>
	</div>
</template>

<script>
import moment from 'moment'

import { mapActions, mapGetters } from 'vuex'
import { hasHistory, omitEmpties } from 'vuelpers'
import { createFormMixin } from '@/mixins/form-mixin'

import UseFilePicker from '@/mixins/UseFilePicker'

const initialEstablishment = (value = {}) => ({
	supplier: {
		id: value.id || null,
		status: value.status || '',
		notes: value.notes || '',
		representativeName: value.representativeName || '',
		representativeAttachment: {
			attachmentName:
				value.attachments && value.attachments.length
					? value.attachments[0].attachmentName
					: '',
			attachedLink:
				value.attachments && value.attachments.length
					? value.attachments[0].attachedLink
					: '',
		},
	},
	company: {
		id: value.company.id || null,
		name: value.company.name || '',
		registrationNumber: value.company.registrationNumber || '',
		recordExpDate:
			moment(value.company.recordExpDate).format('YYYY-MM-DD') || null,
		storeName: value.company.storeName || '',
		email: value.company.email || '',
		phone: value.company.phone || null,
		mainActivity: value?.company?.mainActivity?.name || null,
		subActivity: value?.company?.subActivity?.name || null,
		proofOfRegistration: {
			attachmentName: value.company.proofOfRegistration
				? value.company.proofOfRegistration.attachmentName
				: '',
			attachedLink: value.bank.proofOfAccount
				? value.company.proofOfRegistration.attachedLink
				: '',
		},
	},
	bank: {
		id: value.bank.id || null,
		accountName: value.bank.accountName || '',
		accountNumber: value.bank.accountNumber.startsWith('SA')
			? value.bank.accountNumber.replace('SA', '')
			: value.bank.accountNumber || '',
		bankName: value.bank.bankName || '',
		proofOfAccount: {
			attachmentName: value.bank.proofOfAccount
				? value.bank.proofOfAccount.attachmentName
				: '',
			attachedLink: value.bank.proofOfAccount
				? value.bank.proofOfAccount.attachedLink
				: '',
		},
	},
	address: {
		id: value.address.length ? value.address[0].id : '',
		city: value.address.length ? value.address[0].city : '',
		postalCode: value.address.length ? value.address[0].postalCode : '',
		extraNumber: value.address.length ? value.address[0].extraNumber : '',
		streetName: value.address.length ? value.address[0].streetName : '',
		neighborhoodName: value.address.length
			? value.address[0].neighborhoodName
			: '',
		unitNumber: value.address.length ? value.address[0].unitNumber : '',
		buildingNumber: value.address.length
			? value.address[0].buildingNumber
			: '',
		proofOfAddress: {
			attachmentName:
				value.address.length && value.address[0].proofOfAddress
					? value.address[0].proofOfAddress.attachmentName
					: '',

			attachedLink:
				value.address.length && value.address[0].proofOfAddress
					? value.address[0].proofOfAddress.attachedLink
					: '',
		},
	},
})
export default {
	name: 'approval',
	mixins: [
		createFormMixin({
			rules: ['required', 'email', 'password'],
		}),
		UseFilePicker,
	],
	data() {
		return {
			establishment: {
				supplier: {
					id: null,
					status: '',
					notes: '',
					representativeName: '',
					representativeAttachment: {
						attachmentName: '',
						attachedLink: '',
					},
				},
				company: {
					id: null,
					name: '',
					registrationNumber: '',
					recordExpDate: '',
					storeName: '',
					email: '',
					phone: '',
					mainActivity: '',
					subActivity: '',
					proofOfRegistration: {
						attachmentName: '',
						attachedLink: '',
					},
				},
				bank: {
					id: null,
					accountName: '',
					accountNumber: '',
					bankId: '',
					proofOfAccount: {
						attachmentName: '',
						attachedLink: '',
					},
				},
				address: {
					id: null,
					city: '',
					postalCode: '',
					extraNumber: '',
					streetName: '',
					neighborhoodName: '',
					unitNumber: '',
					buildingNumber: '',
					proofOfAddress: {
						attachmentName: '',
						attachedLink: '',
					},
				},
			},
			success: null,
			menu: false,
			showCompanyAttachError: false,
			showAddressAttachError: false,
			showBankAttachError: false,
			showRepresentativeAttachment: false,
			loading: false,
			approval: {
				status: 'active',
				id: this.$route.params.id,
				reason: null,
				notes: '',
			},
		}
	},
	watch: {
		'establishment.company.mainActivity'() {
			if (
				!this.subActivity.some((sCat) => {
					return sCat.id == this.establishment.company.subActivity
				})
			) {
				// this.establishment.company.subActivity = ''
			}
		},
	},
	computed: {
		...mapGetters('category', [
			'$supplierCategories',
			'$buyerCategories',
			'$rejectCategories',
		]),
		...mapGetters('bank', ['$banks']),
		...mapGetters('auth', ['$currentUser']),
		...mapGetters('settings', ['$globalLoading']),
		...mapGetters('suppliers', ['$supplier']),
		...mapGetters('buyers', ['$buyer']),
		statuses() {
			return this.$t('adminApproval.approvedStatus').filter((status) => {
				return status.value !== 'pending'
			})
		},
		activity() {
			return this.$supplierCategories.data.map((d) => ({
				id: d.id,
				name: d.name,
			}))
		},
		subActivity() {
			let category = this.$supplierCategories.data.find(
				(sub) => sub.id === this.establishment.company.mainActivity
			)

			if (!category) {
				// this.establishment.company.subActivity = ''
				return []
			}
			return category.childCategories.map((a) => ({
				id: a.id,
				name: a.name,
			}))
		},
		attachments() {
			const { type } = this.$route.query
			const v = type === 'buyer' ? this.$buyer : this.$supplier
			return [
				{
					src: this.$image(v?.company?.proofOfRegistration?.attachedLink),
					file: {
						category: this.$t('adminApproval.CompanyAttachment'),
						size: v?.company?.proofOfRegistration?.attachedSize,
						name: v?.company?.proofOfRegistration?.attachmentName,
					},
				},
				{
					src: this.$image(
						this.$get(v, 'address.0.proofOfAddress.attachedLink')
					),
					file: {
						category: this.$t('adminApproval.addressAttachment'),
						size: this.$get(v, 'address.0.proofOfAddress.attachedSize'),
						name: this.$get(v, 'address.0.proofOfAddress.attachmentName'),
					},
				},
				{
					src: this.$image(v?.bank?.proofOfAccount?.attachedLink),
					file: {
						category: this.$t('adminApproval.bankAttachment'),
						size: v?.bank?.proofOfAccount?.attachedSize,
						name: v?.bank?.proofOfAccount?.attachmentName,
					},
				},
				{
					src: this.$image(this.$get(v, 'attachments.0.attachedLink')),
					file: {
						category: this.$t('adminApproval.representativeAttachment'),
						size: this.$get(v, 'attachments.0.attachedSize'),
						name: this.$get(v, 'attachments.0.attachmentName'),
					},
				},
			]
		},
	},
	methods: {
		...mapActions('category', ['getCategories']),
		...mapActions('suppliers', ['getSingleSupplier', 'supplierApprove']),
		...mapActions('buyers', ['getSingleBuyer', 'buyerApprove']),
		onClickBack() {
			return hasHistory() ? this.$router.back() : this.$router.push('/')
		},
		async initializeBuyer() {
			await this.getCategories({ per_page: 1000 })
			const [err, suc] = await this.getSingleBuyer(this.$route.params.id)
			!err && (this.establishment = initialEstablishment(suc.buyer))
		},
		async initializeSupplier() {
			await this.getCategories({ per_page: 1000 })
			const [err, suc] = await this.getSingleSupplier(this.$route.params.id)
			!err && (this.establishment = initialEstablishment(suc.supplier))
		},
		async onSubmitApproved() {
			if (!this.$refs.approval.validate()) {
				this.$utils.scrollIntoError(this)
				return
			}

			this.confirmMsg =
				this.approval.status == 'active'
					? this.$t('buyer-registration-approved-successfully')
					: this.$t('request-declined-successfully')
			let [err] =
				this.$route.query.type === 'buyer'
					? await this.buyerApprove(omitEmpties({ buyer: this.approval }))
					: await this.supplierApprove(
							omitEmpties({ supplier: this.approval })
					  )
			if (err) {
				this.$utils.scrollIntoError(this)
				this.$toast.success(this.$t('an-unexpected-error-has-occured'))
			} else {
				this.$toast.success(this.confirmMsg)
				this.$router.back()
			}
		},
	},
	async created() {
		this.$store.commit('settings/SET', {
			globalLoading: true,
		})
		this.$route.query.type === 'buyer'
			? await this.initializeBuyer()
			: await this.initializeSupplier()
		this.$store.commit('settings/SET', {
			globalLoading: false,
		})
	},
}
</script>

<style lang="scss">
.attachment-table {
	.v-data-table {
		.v-data-table__wrapper {
			tbody > tr > td {
				font-size: 14px;
				color: #000 !important;
				font-weight: 600 !important;
				border-bottom: 1px solid #f2f2f2 !important;
			}
		}
	}
}
</style>
