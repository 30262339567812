export default {
	name: 'UseFilePicker',
	data: () => ({
		ufpAccept: {
			defaultRule: 'image/*,.pdf',
			image: '/images/png',
		},
	}),
	methods: {
		async toBase64(file) {
			return new Promise(resolve => {
				const fileReader = new FileReader()
				fileReader.addEventListener('load', e => {
					resolve(e.target.result)
				})
				fileReader.readAsDataURL(file)
			})
		},
		createElement(tag, attrs = {}) {
			const el = document.createElement(tag)
			attrs &&
				Object.entries(attrs).forEach(([attr, value]) => (el[attr] = value))
			return el
		},
		async onFilePicked(e, config, callback) {
			const images = await Promise.all(
				[...e.target.files].map(async file => ({
					file,
					src: config.base64
						? await this.toBase64(file)
						: URL.createObjectURL(file),
				}))
			)
			callback(config.multiple ? images : images[0])
		},
		openFilePicker(config, callback) {
			this.createElement('input', {
				type: 'file',
				accept:
					config.accept ?? this.ufpAccept[config.type || 'defaultRule'],
				multiple: config.multiple || false,
				onchange: async e => {
					this.onFilePicked(e, config, callback)
				},
			}).click()
		},
	},
}
